// @flow
import React from 'react';
import { Location } from '@reach/router';
import cx from 'classnames';
import styles from './Success.module.scss';
import IllustrationSuccess from '../../images/icon-success.svg';
import useNavigateBackToTop from '../../hooks/useNavigateBackToTop';

const headingMap = {
  '/email/unsubscribe/success': 'ありがとうございます',
  '/email/resubscribe/success': '配信再開を完了しました',
};

const Success = () => {
  useNavigateBackToTop();

  return (
    <Location>
      {({ location: { pathname } }) => (
        <div className={styles.wrapper}>
          <h2 className={cx(styles.heading, 'paidy-pad-hor-9 paidy-title-01')}>
            {headingMap[pathname]}
          </h2>
          <div className={styles.imageWrapper}>
            <img
              className={styles.image}
              src={IllustrationSuccess}
              alt="success"
            />
          </div>
        </div>
      )}
    </Location>
  );
};

export default Success;
