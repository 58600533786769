// @flow
import React from 'react';

import Layout from '../../../../components/Layout/Layout';
import Success from '../../../../components/Success/Success';

const ResubscribeSuccess = () => (
  <Layout hasNoHeader hasNoFooter>
    <Success />
  </Layout>
);

export default ResubscribeSuccess;
